import React from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout"
import {GatsbySeo} from 'gatsby-plugin-next-seo/src/meta/gatsby-seo';
import useSiteMetadata from '../hooks/use-site-metadata';

const VarliktanVerilerPage = ({ data, location }) => {
    const posts = data.allMarkdownRemark.nodes
    const { siteUrl, defaultImage } = useSiteMetadata();

    return (
        <Layout>
            <GatsbySeo
                title="Varlıktan Veriler"
                description="Kazım Yardımcı tarafından yazılan yazı serisininin tamamını bulabilirsiniz"
                openGraph={{
                    url: `${siteUrl}${location.pathname}`,
                    title: "Varlıktan Veriler",
                    images: [
                        {
                            url: `${siteUrl}${defaultImage}`,
                            width: 1200,
                            height: 630
                        }
                    ]
                }}
            />
            <section id="page-title" className="page-title-center">

                <div className="container">
                    <h1>Varlıktan Veriler</h1>
                    <span>Varlıktan verileri insanlığa armağan ediyoruz</span>
                    {/*<ol className="breadcrumb">*/}
                    {/*    <li className="breadcrumb-item"><a href="#">Home</a></li>*/}
                    {/*    <li className="breadcrumb-item active" aria-current="page">Blog</li>*/}
                    {/*</ol>*/}
                </div>

            </section>
            <section id="content">
                <div className="content-wrap pt-5">
                    <div className="container">

                        <div className="row gutter-50">
                            <div className="col-lg-12 mt-lg-0">

                                <div className="row col-mb-50 posts-md">
                                    {posts.map(post => {

                                        return (
                                            <div className="col-md-6" key={post.frontmatter.slug}>
                                                <article className="entry">
                                                    {/*<div className="entry-image mb-3">*/}
                                                    {/*    <Link to={`/${post.frontmatter.slug}`}><img src={post.frontmatter.featuredImage?.childImageSharp.fluid.src || defaultImage} alt={post.frontmatter.title}/></Link>*/}
                                                    {/*</div>*/}
                                                    <div className="entry-title title-sm">
                                                        <div className="entry-categories">Varlıktan Veriler - {post.frontmatter.no}</div>
                                                        <h3><Link to={`/${post.frontmatter.slug}`} className="color-underline stretched-link" >{post.frontmatter.title}</Link></h3>
                                                    </div>
                                                    {/*<div className="entry-meta">*/}
                                                    {/*    <ul>*/}
                                                    {/*        <li>{post.frontmatter.date}</li>*/}
                                                    {/*    </ul>*/}
                                                    {/*</div>*/}
                                                    {/*<div className="entry-content mt-3">*/}
                                                    {/*    <p>{post.excerpt}</p>*/}
                                                    {/*    <Link to={`/${post.frontmatter.slug}`} className="more-link">Devamını Oku</Link>*/}
                                                    {/*</div>*/}
                                                </article>
                                            </div>
                                        )
                                    })}

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default VarliktanVerilerPage

export const pageQuery = graphql`
  query allVarliktanVeriler {
      allMarkdownRemark(
                filter: {fileAbsolutePath: {regex: "/(/content/varliktan-veriler)/"  }}
                sort: { fields: [frontmatter___no], order: ASC }
                ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          slug
          no
          featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }
      }
    }
  }
`
